import React from 'react'


// Simple header
function Header() {
    return (
        <h1 className="header">
        Wordle Solver
        </h1>
    )
}

export default Header;